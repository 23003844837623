import React from "react"
import Layout from "../components/layout"
import { Container, Grid, Box, Heading } from "theme-ui"

const Changelog = () => {
  return (
    <Layout variant="sidebar">
      <Container variant="fluid" >
        <Grid gap={[2, 2, 3, 1]} row={[1]} columns={[1]} sx={{ backgroundColor: ['white', 'transparent'], display: 'relative', paddingBottom: '5em'}}>
        <Box sx={{
              overflow: "hidden",
              position: 'relative',
              display: 'block',
              width: '80%',
              margin: '2em auto'
            }}>
            <Heading as="h1" variant="h1" className='soft-faux-font'>
              Changelog
            </Heading>
          </Box>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Changelog